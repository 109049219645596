import { InjectionToken, inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

export const IS_PLATFORM_BROWSER = new InjectionToken<boolean>(
  'IS_PLATFORM_BROWSER',
  {
    providedIn: 'root',
    factory: () => {
      const platformId = inject(PLATFORM_ID);
      return isPlatformBrowser(platformId);
    },
  }
);

export const IS_PLATFORM_SERVER = new InjectionToken('IS_PLATFORM_SERVER', {
  providedIn: 'root',
  factory: () => {
    const platformId = inject(PLATFORM_ID);
    return isPlatformServer(platformId);
  },
});
