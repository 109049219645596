import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { LayoutModule } from './layout';
import { CoreComponent } from './core.component';
import { RootStore } from './store/root.store';
import { INTERCEPTOR_PROVIDERS } from './interceptors';

@NgModule({
  declarations: [CoreComponent],
  imports: [CommonModule, LayoutModule, RouterModule],
  exports: [CoreComponent],
  providers: [INTERCEPTOR_PROVIDERS],
})
export class CoreModule {
  constructor(private rootStore: RootStore) {}
}
