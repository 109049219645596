// Angular specific
import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

// Project specific
import { RootStore } from '@app/core';

@Injectable({
  providedIn: 'root',
})
export class OrdersGuard implements CanActivate, CanActivateChild {
  private ordersIsAvailable$ = this.rootStore.ordersIsAvailable$;

  constructor(private router: Router, private rootStore: RootStore) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.ordersIsAvailable$.pipe(
      take(1),
      map((ordersIsAvailable) =>
        ordersIsAvailable ? true : this.router.createUrlTree(['/'])
      )
    );
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.ordersIsAvailable$.pipe(
      take(1),
      map((ordersIsAvailable) =>
        ordersIsAvailable ? true : this.router.createUrlTree(['/'])
      )
    );
  }
}
