import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@env/environment';

import {
  AccountModel,
  AccountReferToVerifyData,
  AccountResetPasswordData,
  AccountUpdateData,
} from './types';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private readonly baseURL = `${environment.apiUrl}/account`;

  constructor(private http: HttpClient) {}

  getAccount(accountId: number): Observable<AccountModel> {
    const url = `${this.baseURL}/${accountId}`;

    return this.http
      .get<AccountModel>(url)
      .pipe(
        catchError((err: HttpErrorResponse) =>
          throwError(new Error(err.error.message))
        )
      );
  }

  verifyEmail(
    accountId: number,
    hash: string
  ): Observable<{ modifiedId: number }> {
    const url = `${this.baseURL}/${accountId}/_verify_email`;

    return this.http
      .post<{ modifiedId: number }>(url, { hash })
      .pipe(
        catchError((err: HttpErrorResponse) =>
          throwError(new Error(err.error.message))
        )
      );
  }

  resendEmailVerification(email: string) {
    const url = `${this.baseURL}/resend_email_verification`;
    return this.http.post(url, { email });
  }

  resetPassword(email: string): Observable<{ modifiedId: number }> {
    const url = `${this.baseURL}/reset_password`;

    return this.http
      .post<{ modifiedId: number }>(url, { email })
      .pipe(
        catchError((err: HttpErrorResponse) =>
          throwError(new Error(err.error.message))
        )
      );
  }

  referToVerify(
    data: AccountReferToVerifyData
  ): Observable<{ modifiedId: number }> {
    const url = `${this.baseURL}/refer_to_verify`;

    return this.http
      .post<{ modifiedId: number }>(url, data)
      .pipe
      // catchError((err: HttpErrorResponse) =>
      //   throwError(new Error(err.error.message))
      // )
      ();
  }

  recoveryPassword(
    data: AccountResetPasswordData
  ): Observable<{ modifiedId: number }> {
    const url = `${this.baseURL}/recovery_password`;

    return this.http
      .post<{ modifiedId: number }>(url, data)
      .pipe(
        catchError((err: HttpErrorResponse) =>
          throwError(new Error(err.error.message))
        )
      );
  }

  updateAccount(accountId: number, data: AccountUpdateData) {
    const url = `${this.baseURL}/${accountId}/update`;

    return this.http
      .post<{ modifiedId: number }>(url, data)
      .pipe(
        catchError((err: HttpErrorResponse) =>
          throwError(new Error(err.error.message))
        )
      );
  }
}
