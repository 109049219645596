import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CookieService } from '@gorniv/ngx-universal';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '@env/environment';

import { AccountModel } from './types';
import { LOCATION } from '@ng-web-apis/common';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly baseURL = `${environment.apiUrl}/account`;

  private readonly decodedTokenSubject = new BehaviorSubject<Partial<AccountModel> | null>(
    null
  );

  readonly decodedToken$ = this.decodedTokenSubject.asObservable();

  private redirectUrl: string | null = null;

  constructor(
    private router: Router,
    private http: HttpClient,
    private cookieService: CookieService,
    private jwtHelperService: JwtHelperService,
    @Inject(LOCATION) private location: Location
  ) {
    // console.log({ authAccount: this.getDecodedToken() });
    this.decodedTokenSubject.next(this.getDecodedToken());
  }

  setToken(token: string | null) {
    const decoded = this.jwtHelperService.decodeToken(token);

    if (decoded) {
      this.cookieService.remove('usertoken', {
        domain: '/',
      });

      // tslint:disable-next-line:one-variable-per-declaration
      const expires = this.jwtHelperService.getTokenExpirationDate(token),
        path = '/',
        domain = null,
        secure = !(
          this.location.hostname === 'localhost' ||
          this.location.hostname === '10.254.112.91' ||
          this.location.hostname === '192.168.5.74'
        );

      this.cookieService.put('usertoken', token, {
        expires,
        path,
        domain,
        secure,
      });

      this.decodedTokenSubject.next(this.getDecodedToken());
    } else {
      this.cookieService.remove('usertoken', {
        path: '/',
      });
      this.decodedTokenSubject.next(null);
    }
  }

  getToken() {
    if (this.jwtHelperService.isTokenExpired()) {
      return null;
    }
    return this.jwtHelperService.tokenGetter();
  }

  getDecodedToken(): any | null {
    try {
      return this.jwtHelperService.decodeToken<Partial<AccountModel>>();
    } catch (e) {
      return null;
    }
  }

  setRedirectUrl(url: string | null) {
    this.redirectUrl = url;
  }

  getRedirectUrl() {
    return this.redirectUrl;
  }

  register(
    email: string,
    password: string
  ): Observable<{ insertedId: number }> {
    const url = this.baseURL;

    return this.http
      .post<{ insertedId: number }>(url, { email, password })
      .pipe(
        catchError((err: HttpErrorResponse) =>
          throwError(new Error(err.error.message))
        )
      );
  }

  login(username: string, password: string): Observable<{ token: string }> {
    const url = `${this.baseURL}/token`;

    return this.http
      .post<{ token: string }>(url, { username, password })
      .pipe(
        tap(({ token }) => {
          this.setToken(token);
        })
        // catchError((err: HttpErrorResponse) =>
        //   throwError(new Error(err.error.message))
        // )
      );
  }

  logout() {
    this.setToken(null);

    this.router.navigate(['/auth/sign-in'], {}).then(() => {});
  }
}
