import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormErrorMessagePipe } from './form-error-message.pipe';

@NgModule({
  declarations: [ FormErrorMessagePipe ],
  imports: [ CommonModule ],
  exports: [ FormErrorMessagePipe ]
})
export class FormErrorMessageModule { }
