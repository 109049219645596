import { isDevMode } from '@angular/core';
import { isPlatformServer } from '@angular/common';

export const stateLogger = (name: string) => (state: object) => {
  if (isDevMode()) {
    console.groupCollapsed(`%c[${name}] state`, 'color: skyblue;');
    console.log(state);
    console.groupEnd();
  }
};

export enum CallState {
  Init,
  Loading,
  Loaded,
  Error,
}

export enum GetCallState {
  Init,
  Loading,
  Loaded,
  Error,
}

export enum PostCallState {
  Init,
  Processing,
  Processed,
  Error,
}

export enum DeleteCallState {
  Init,
  Deleting,
  Deleted,
  Error,
}
