import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { EnvironmentTarget } from '@env/target';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  constructor() {}

  getApiUrlsMap() {
    const { apiUrl, mediaUrl } = environment;
    return { apiUrl, mediaUrl };
  }

  getTarget(): EnvironmentTarget {
    return environment.target;
  }
}
