import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanActivateChild,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AuthService } from '@app/core/api';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean | UrlTree {
    if (route.data.needAuth) {
      return this.authService.decodedToken$.pipe(
        take(1),
        map((decodedToken) =>
          !!decodedToken ? true : this.router.createUrlTree(['/'])
        )
      );
    }

    return true;
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean | UrlTree {
    if (childRoute.data.needAuth) {
      return this.authService.decodedToken$.pipe(
        map((decodedToken) =>
          !!decodedToken ? true : this.router.createUrlTree(['/'])
        )
      );
    }

    return true;
  }
}
