import { AccountRole, AccountStatus, AccountVerificationStatus } from './types';

export const configs = {
  email: {
    valid_pattern:
      '^(([^<>()\\[\\]\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
  },
  password: {
    valid_pattern: '^(?=.*[0-9])(?=.*[A-Z])[A-Za-z0-9?!.,*_+-@]{6,30}$',
    min: 6,
    max: 30,
  },
  facebook_url: {
    valid_pattern: '^(https?://)?(www.)?(m.)?(facebook.com/)?[a-zA-Z0-9(.?)?]',
  },
  instagram_url: {
    valid_pattern: '(https?)?:?(www)?instagram.com/[a-z]*[0-9]*',
  },
  minimum_age: 18,
  statuses: [
    AccountStatus.NotVerified,
    AccountStatus.Active,
    AccountStatus.Disabled,
  ],
  verification_statuses: [
    AccountVerificationStatus.NotVerified,
    AccountVerificationStatus.Pending,
    AccountVerificationStatus.Verified,
    AccountVerificationStatus.Rejected,
  ],
  account_roles: [
    AccountRole.Admin,
    AccountRole.Moderator,
    AccountRole.Customer,
  ],
} as const;

export type Configs = typeof configs;
