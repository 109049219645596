import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, tap, mapTo } from 'rxjs/operators';

import { environment } from '@env/environment';
import {
  EventModel,
  EventsRequestFilter,
  EventStatus,
  EventType,
  EventBasket,
  BasketType,
} from './types';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  private readonly baseURL = `${environment.apiUrl}/event`;

  constructor(private httpClient: HttpClient) {}

  getById(eventId: number): Observable<EventModel> {
    const url = `${this.baseURL}/${eventId}`;

    return this.httpClient.get<EventModel>(url).pipe(
      map((event) => ({
        ...event,
        // baskets: (event.baskets || []).map((item) => ({
        //   ...item,
        //   expired:
        //     new Date(item.date_to as any).getTime() <= new Date().getTime(),
        // })),
      }))
    );
  }

  getByFilter(
    filter: Partial<EventsRequestFilter>
  ): Observable<{ count: number; data: EventModel[] }> {
    const url = `${this.baseURL}/list`;

    let params = new HttpParams()
      .set('limit', filter.limit.toString())
      .set('offset', filter.offset.toString());

    if (filter.account_id) {
      params = params.set('account_id', filter.account_id.toString());
    }

    if (filter.type) {
      params = params.set('type', filter.type);
    }

    if (filter.status) {
      params = params.set('status', filter.status);
    }

    if (filter.date_start) {
      params = params.set('date_start', filter.date_start.toString());
    }

    if (filter.date_end) {
      params = params.set('date_end', filter.date_end.toString());
    }

    return this.httpClient
      .get<{ count: number; data: EventModel[] }>(url, { params })
      .pipe(
        catchError((err: HttpErrorResponse) =>
          throwError(new Error(err.error.message))
        )
      );
  }

  getBulk({ ids }: { ids: number[] }): Observable<EventModel[]> {
    const url = `${this.baseURL}/_bulk`;

    return this.httpClient.post<EventModel[]>(url, { ids });
  }

  isBasketExpired(
    basketType: BasketType,
    basket: EventBasket,
    boughtTickets: number
  ) {
    switch (basketType) {
      case BasketType.Date:
        return (
          new Date(basket.date_to as any).getTime() <= new Date().getTime()
        );
      case BasketType.DateOrCount:
        return (
          new Date(basket.date_to as any).getTime() <= new Date().getTime() ||
          !(basket.count - boughtTickets > 0)
        );
      case BasketType.Count:
        return !(basket.count - boughtTickets > 0);
    }
  }
}
