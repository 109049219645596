import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { AboutUsMobileComponent } from './about-us-mobile.component';
import { AboutUsMobileService } from './about-us-mobile.service';

@NgModule({
  declarations: [AboutUsMobileComponent],
  imports: [CommonModule, MatIconModule],
  providers: [AboutUsMobileService],
})
export class AboutUsMobileModule {}
