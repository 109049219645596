import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveComponentModule } from '@ngrx/component';
import { OverlayModule } from '@angular/cdk/overlay';

import { LayoutComponent } from './layout.component';
import { AboutUsMobileModule } from '@app/core/layout/about-us-mobile/about-us-mobile.module';

@NgModule({
  declarations: [LayoutComponent],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveComponentModule,
    OverlayModule,
    AboutUsMobileModule,
  ],
  exports: [LayoutComponent],
})
export class LayoutModule {}
