import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CoreComponent } from './core';
import { AuthGuard, IsLoggedGuard, OrdersGuard } from './core/guards';

const routes: Routes = [
  // { path: '', pathMatch: 'full', redirectTo: '/events' },
  {
    path: '',
    component: CoreComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./events/events.module').then(
            (exports) => exports.EventsModule
          ),
      },
      {
        path: 'auth',
        loadChildren: () =>
          import('./auth/auth.module').then((exports) => exports.AuthModule),
        canActivate: [IsLoggedGuard],
      },
      {
        path: 'email-verification',
        loadChildren: () =>
          import('./email-verification/email-verification.module').then(
            (exports) => exports.EmailVerificationModule
          ),
      },
      {
        path: 'password-verification',
        loadChildren: () =>
          import('./password-verification/password-verification.module').then(
            (exports) => exports.PasswordVerificationModule
          ),
      },
      {
        path: 'account',
        data: { needAuth: true },
        loadChildren: () =>
          import('./account/account.module').then(
            (exports) => exports.AccountModule
          ),
      },
      {
        path: 'payment',
        data: { needAuth: true },
        canActivate: [OrdersGuard],
        loadChildren: () =>
          import('./payment/payment.module').then(
            (exports) => exports.PaymentModule
          ),
      },
      {
        path: 'orders',
        data: { needAuth: true },
        canActivate: [OrdersGuard],
        loadChildren: () =>
          import('./orders/orders.module').then(
            (exports) => exports.OrdersModule
          ),
      },
      {
        path: 'terms-and-conditions',
        loadChildren: () =>
          import('./terms-and-conditions/terms-and-conditions.module').then(
            (exports) => exports.TermsAndConditionsModule
          ),
      },
    ],
  },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
