import { Component, OnInit } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';
import { AboutUsService } from '@app/core/services';

@Component({
  selector: 'app-about-us-mobile',
  templateUrl: './about-us-mobile.component.html',
  styleUrls: ['./about-us-mobile.component.scss'],
})
export class AboutUsMobileComponent implements OnInit {
  readonly aboutUs = this.aboutUsService.aboutUs;

  constructor(
    public overlayRef: OverlayRef,
    private aboutUsService: AboutUsService
  ) {}

  ngOnInit() {}
}
