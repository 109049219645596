import { ComponentRef, Injectable, Injector } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { AboutUsMobileComponent } from './about-us-mobile.component';

@Injectable()
export class AboutUsMobileService {
  constructor(private overlay: Overlay, private injector: Injector) {}

  openFloatingPanel(): OverlayRef {
    const overlayRef = this.overlay.create({
      disposeOnNavigation: true,
      // direction: 'ltr',
      hasBackdrop: true,
      panelClass: 'about-us-panel-class',
      // backdropClass: 'backdropClass',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: this.overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically(),

      width: '100%',
      // minWidth: '100%',
      // maxWidth: '100%',
      //
      height: '100%',
      // minHeight: '100%',
      // maxHeight: '100%',
    });

    const contactsPortal = new ComponentPortal(
      AboutUsMobileComponent,
      null,
      Injector.create({
        providers: [{ provide: OverlayRef, useValue: overlayRef }],
        parent: this.injector,
      })
    );

    const containerRef: ComponentRef<AboutUsMobileComponent> = overlayRef.attach(
      contactsPortal
    );

    // overlayRef.backdropClick().subscribe((mouseEvent: MouseEvent) => {
    //   overlayRef.detach();
    // });

    return overlayRef;
  }
}
