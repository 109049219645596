import { Injectable, isDevMode } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AppMetaService {
  private readonly localMetaDataUrl: string;

  private readonly metaData$: Observable<IAppMetaData>;

  constructor(
    private httpClient: HttpClient,
    private meta: Meta,
    private title: Title
  ) {
    // this.localMetaDataUrl = isDevMode()
    //   ? '/assets/meta.json'
    //   : '/assets/meta.json';

    // this.metaData$ = this.httpClient
    //   .get<IAppMetaData>(this.localMetaDataUrl)
    //   .pipe(shareReplay(1));

    this.metaData$ = of(metaData);
  }

  setTitle(namespace: string): void {
    this.metaData$.subscribe((metaData) => {
      const { app } = metaData[namespace];
      this.title.setTitle(app.title);
    });
  }

  setMetas(namespace: string, variables?: IPageMetaData): void {
    this.metaData$.subscribe((metaData) => {
      const ogGlobal = metaData['og-global'];
      const { app, og } = metaData[namespace];

      this.title.setTitle(replacer(app.title, variables?.app?.title));

      this.meta.updateTag({
        name: 'description',
        content: replacer(app.description, variables?.app?.description),
      });

      for (const key in og) {
        if (og.hasOwnProperty(key)) {
          const propertyName = `og:${key}`;

          this.meta.updateTag({
            property: propertyName,
            content:
              og[key] === null
                ? ogGlobal[key]
                : replacer(og[key], variables?.og[key]),
          });
        }
      }
    });
  }

  // private setMetas(data: Array<{ name: string; content: string }>) {
  //   this.meta.addTags(data);
  // }
  //
  // private setOgMetas(data: Array<{ property: FacebookMetaProperty; content: string }>) {
  //   this.meta.addTags(data);
  // }
  //
  // setTwitterMetas(
  //   data: Array<{ property: TwitterMeatProperty; content: string }>
  // ) {}
}

export interface IPageWithMetas<T = void> {
  setMetas(data?: T): void;
}

type HashMap = { [key: string]: string };

interface IOgGlobal {
  'og-global': {
    url: string;
    type: string;
    description: string;
    image: string;
    facebook: { app_id: string };
  };
}

interface IPageMetaData {
  app?: {
    title?: HashMap;
    description?: HashMap;
  };
  og?: {
    url?: HashMap;
    title?: HashMap;
    description?: HashMap;
    image?: HashMap;
  };
}

interface IAppMetaData extends IOgGlobal, IPageMetaData {}

function replacer(value: string, params: HashMap = {}) {
  const interpolationMatcher = new RegExp('{{(.*?)}}', 'g');

  return value.replace(interpolationMatcher, (_, match) => {
    match = match.trim();

    if (params[match]) {
      return params[match];
    }

    return `*${match}*`;
  });
}

const metaData = {
  'og-global': {
    url: 'http://www.poligraf.am',
    type: 'website',
    description:
      'Poligraf is a multi-functional space containing multiple moods and vibes, where each can find his reflection. We host various types of events, such as clubnights, concerts, workshops, art exhibitions and more. We are open to all, who search for new experiences.',
    image: 'https://poligraf.am/assets/images/fb-poligraf.jpg',
    facebook: {
      app_id: '',
    },
  },

  home: {
    app: {
      title: 'Poligraf - Performance & Event Venue',
      description:
        'Poligraf is a multi-functional space containing multiple moods and vibes, where each can find his reflection.',
      follow: true,
      index: true,
    },
    og: {
      url: 'http://www.poligraf.am',
      title: 'Poligraf - Performance & Event Venue',
      description:
        'Poligraf is a multi-functional space containing multiple moods and vibes, where each can find his reflection. We host various types of events, such as clubnights, concerts, workshops, art exhibitions and more. We are open to all, who search for new experiences.',
      image: 'https://poligraf.am/assets/images/fb-poligraf.jpg',
    },
  },

  'event-details': {
    app: {
      title: '{{eventTitle}} - Poligraf',
      description: '{{eventDescription}}',
      follow: true,
      index: true,
    },
    og: {
      url: 'http://www.poligraf.am/event/{{eventId}}',
      title: '{{eventTitle}}',
      description: '{{eventDescription}}',
      image: '{{eventImageUrl}}',
    },
  },

  'sign-up': {
    app: {
      title: 'Sign Up - Poligraf',
      description: 'Sign Up to buy tickets for the event',
      follow: true,
      index: true,
    },
    og: {
      url: 'https://poligraf.am/auth/sign-up',
      title: 'Sign Up - Poligraf',
      description: 'Sign Up to buy tickets for the event',
      image: null,
    },
  },

  'sign-in': {
    app: {
      title: 'Sign In - Poligraf',
      description: 'Sign In to buy tickets for the event',
      follow: true,
      index: true,
    },
    og: {
      url: 'https://poligraf.am/auth/sign-in',
      title: 'Sign In - Poligraf',
      description: 'Sign In to buy tickets for the event',
      image: null,
    },
  },

  'reset-password': {
    app: {
      title: 'Reset Password - Poligraf',
      description: 'Reset User Password for login',
      follow: true,
      index: true,
    },
    og: {
      url: 'https://poligraf.am/auth/reset-password',
      title: 'Reset Password - Poligraf',
      description: 'Reset User Password for login',
      image: null,
    },
  },

  'email-verification': {
    app: {
      title: 'Email Verification - Poligraf',
    },
  },

  'password-verification': {
    app: {
      title: 'Password Verification - Poligraf',
    },
  },

  'terms-and-conditions': {
    app: {
      title: 'Terms And Conditions - Poligraf',
      description:
        'Remember – it is strictly forbidden to: use telephone screen light or flashlight photo and video recording. Entrance of persons under 18. Bringing flammable/explosive materials. Giving out tickets to another person. Smoking is prohibited inside the club.',
      follow: true,
      index: true,
    },
    og: {
      url: 'https://poligraf.am/terms-and-conditions',
      title: 'Terms And Conditions - Poligraf',
      description:
        'Remember – it is strictly forbidden to: use telephone screen light or flashlight photo and video recording. Entrance of persons under 18. Bringing flammable/explosive materials. Giving out tickets to another person. Smoking is prohibited inside the club.',
      image: null,
    },
  },

  account: {
    app: {
      title: 'Account - Poligraf',
    },
  },

  orders: {
    app: {
      title: 'Orders - Poligraf',
    },
  },

  'order-details': {
    app: {
      title: 'Orders Details - Poligraf',
    },
  },
};
