import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ComponentStore, INITIAL_STATE_TOKEN } from '@ngrx/component-store';

import { AuthService } from '@app/core/api';
import { RootStore } from '@app/core/store';
import { AboutUsService } from '@app/core/services';
import { AboutUsMobileService } from './about-us-mobile';
import { LayoutState, NavLink } from './types';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  providers: [
    {
      provide: INITIAL_STATE_TOKEN,
      useFactory: () => ({ mobileAboutAsMenuIsOpened: false } as LayoutState),
    },
    ComponentStore,
  ],
})
export class LayoutComponent implements OnInit {
  readonly language$ = this.rootStore.language$;
  readonly ordersIsAvailable$ = this.rootStore.ordersIsAvailable$;

  readonly navLinks$: Observable<NavLink[]> = combineLatest([
    this.authService.decodedToken$,
    this.ordersIsAvailable$,
  ]).pipe(
    map(([decodedToken, ordersIsAvailable]) =>
      decodedToken
        ? this.getAuthNavLinks(ordersIsAvailable)
        : this.getGuestNavLinks()
    )
  );

  readonly mobileAboutAsMenuIsOpened$ = this.store.select(
    ({ mobileAboutAsMenuIsOpened }) => mobileAboutAsMenuIsOpened
  );

  readonly showMobileContacts = this.store.effect<void>((origin$) =>
    origin$.pipe(
      tap(() => {
        this.store.setState(({ mobileAboutAsMenuIsOpened }) => ({
          mobileAboutAsMenuIsOpened: !mobileAboutAsMenuIsOpened,
        }));

        this.aboutUsMobileService.openFloatingPanel();
      })
    )
  );

  readonly aboutUs = this.aboutUsService.aboutUs;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    private store: ComponentStore<LayoutState>,
    private authService: AuthService,
    private rootStore: RootStore,
    private aboutUsService: AboutUsService,
    private aboutUsMobileService: AboutUsMobileService
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      const windowProxy = this.document.defaultView;
      const vh = windowProxy.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      this.document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
  }

  getAuthNavLinks(ordersIsAvailable: boolean): NavLink[] {
    const routes = [
      { text: 'account', ngClass: [], routerLinkCommands: ['/account'] },
      {
        text: 'log out',
        ngClass: ['alwaysPassive'],
        routerLinkCommands: undefined,
        action: () => this.onLogout(),
      },
    ];

    if (ordersIsAvailable) {
      routes.unshift({
        text: 'orders',
        ngClass: [],
        routerLinkCommands: ['/orders'],
      });
    }

    return routes;
  }

  getGuestNavLinks(): NavLink[] {
    return [
      {
        text: 'register',
        ngClass: ['register'],
        routerLinkCommands: ['/auth/sign-up'],
      },
      {
        text: 'log in',
        ngClass: ['register'],
        routerLinkCommands: ['/auth/sign-in'],
      },
    ];
  }

  onLogout() {
    this.authService.logout();
  }

  onLangClick(lang: 'en' | 'am') {
    this.rootStore.changeLanguage({ lang });
  }
}
