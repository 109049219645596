import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { map } from 'rxjs/operators';

import { WINDOW } from '@ng-web-apis/common';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
  MediaMatcher,
} from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  isTablet$ = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(map((state: BreakpointState) => state.matches));

  isMobile$ = this.breakpointObserver
    .observe([Breakpoints.XSmall])
    .pipe(map((state: BreakpointState) => state.matches));

  constructor(
    private breakpointObserver: BreakpointObserver,
    private mediaMatcher: MediaMatcher,
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document
  ) {}

  isMobile(): boolean {
    return this.mediaMatcher.matchMedia(Breakpoints.XSmall).matches;
  }
}
