<section class="main-container">
  <!-- Left information Side -->
  <div class="about-us-container">
    <div class="logo">
      <a routerLink="/">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="108"
          height="108"
          viewBox="0 0 130.8 130.55"
        >
          <title>poligraf logo</title>
          <path
            d="M0,0V130.32H90.06V0ZM10,60.3H20V70.49H10Zm0,24.52H40.25v10.5H10ZM30,70.49V30H40.25V77.83Zm30.06,49.83H10v-10H60.06Zm0-19.56v2.06L50,95.32V30H60.06Zm20,19.56h-10V20H20V52.54L10.82,46,10,45.38V10H80.06Z"
            transform="translate(0 0.12)"
            fill="#dcd4c9"
          />
          <path
            d="M97.78,130.44v-7.15q0-.31,0-.68t.06-.75A3.37,3.37,0,0,1,99,120a5.43,5.43,0,0,1,3.54-.85h11.18q3.21,0,4,1.12a4.51,4.51,0,0,1,.91,2.48v3.13h11.6v4.6Zm17.9-5.14a1.91,1.91,0,0,0-.33-1.1,1.42,1.42,0,0,0-1.26-.48H102.42a1.92,1.92,0,0,0-1.18.31,1.53,1.53,0,0,0-.44,1.2l0,.62h14.93Z"
            transform="translate(0 0.12)"
            fill="#dcd4c9"
          />
          <path
            d="M102.49,112.69h-.08l-1,0a7.05,7.05,0,0,1-1.37-.23,4.68,4.68,0,0,1-1.91-1.14,3.58,3.58,0,0,1-.87-2.69v-3.13a3.65,3.65,0,0,1,.85-2.67,4.46,4.46,0,0,1,1.89-1.16,6.52,6.52,0,0,1,1.35-.23l1,0h24.36a4.57,4.57,0,0,1,.81.08,4.16,4.16,0,0,1,2.2.91q1,.79,1,3.11l0,3.13a.67.67,0,0,0,0,.21V109a4.76,4.76,0,0,1-.81,2.55q-.77,1.16-4.06,1.16Zm24.17-4.6h.12a1.22,1.22,0,0,0,.7-.31,1,1,0,0,0,.31-.77,1,1,0,0,0-.29-.75,1.12,1.12,0,0,0-.83-.29H101.33a1,1,0,0,0-.73.25,1.15,1.15,0,0,0-.35.56.13.13,0,0,0,0,.1V107a1,1,0,0,0,.31.73,1.12,1.12,0,0,0,.7.35h25.45Z"
            transform="translate(0 0.12)"
            fill="#dcd4c9"
          />
          <path
            d="M97.78,94.12v-4.6h29.47V84.38h3v9.74Z"
            transform="translate(0 0.12)"
            fill="#dcd4c9"
          />
          <path
            d="M97.78,78.23v-4.6h32.48v4.6Z"
            transform="translate(0 0.12)"
            fill="#dcd4c9"
          />
          <path
            d="M114.52,54.64h11.33q3.67,0,4.29,1.28a6.48,6.48,0,0,1,.62,2.86l0,3.21a4,4,0,0,1-.93,2.92A4.13,4.13,0,0,1,127.94,66a6.1,6.1,0,0,1-1,.14l-.93,0H102.42l-.95,0a10.1,10.1,0,0,1-1.06-.1,4.85,4.85,0,0,1-2.18-1,3.65,3.65,0,0,1-1-2.94V59.24q0-2.71,1-3.54a4,4,0,0,1,2.28-.91h7.5v4.6H101a.11.11,0,0,0-.08,0,.84.84,0,0,0-.54.31,1,1,0,0,0-.23.7,1.16,1.16,0,0,0,.27.75.83.83,0,0,0,.66.33h25.68a1,1,0,0,0,.73-.27,1.62,1.62,0,0,0,.39-.58.65.65,0,0,1,0-.15.6.6,0,0,0,0-.16,1.16,1.16,0,0,0-1.16-1.12h-9.13v1.08h-3.09Z"
            transform="translate(0 0.12)"
            fill="#dcd4c9"
          />
          <path
            d="M97.78,47.41V40.18q0-2.4,1-3.17a4.23,4.23,0,0,1,2.2-.89,5.23,5.23,0,0,1,.64,0h12.16a6.06,6.06,0,0,1,.66,0,5,5,0,0,1,1.8.5,3,3,0,0,1,1.37,1.58l12.65-3.05v4.91l-12,2.44v.31h12v4.6Zm17.56-5.26a1.27,1.27,0,0,0-.54-1.06,2.47,2.47,0,0,0-1.51-.41H102.42a1.73,1.73,0,0,0-1.28.37,1.35,1.35,0,0,0-.39.83v.93h14.58Z"
            transform="translate(0 0.12)"
            fill="#dcd4c9"
          />
          <path
            d="M97.78,26.14V19.22L130.26,16V20.5l-9,.77V24.4l9,.7v4.45Zm20.42-4.64-16.51,1.2v.23l16.51,1.28Z"
            transform="translate(0 0.12)"
            fill="#dcd4c9"
          />
          <path
            d="M97.78,9.28V-.12h3V4.68H112.2V.46h3V4.68h15.08v4.6Z"
            transform="translate(0 0.12)"
            fill="#dcd4c9"
          />
        </svg>
      </a>
    </div>

    <div class="philosophy">
      <p [innerHTML]="aboutUs.philosophy"></p>
    </div>

    <!-- Contacts -->
    <ng-container [ngTemplateOutlet]="contacts"></ng-container>
  </div>

  <!-- Navigation  -->
  <div class="nav">
    <div class="nav-list">
      <a
        *ngFor="let navLink of navLinks$ | async"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: false }"
        [routerLink]="navLink.routerLinkCommands"
        [ngClass]="navLink.ngClass"
        (click)="navLink.action ? navLink.action() : $event.preventDefault()"
      >
        {{ navLink.text }}
      </a>
    </div>

    <!--<div class="lang-list" *ngrxLet="language$; let language">
      <a [class.active]="language === 'en'" (click)="onLangClick('en')">eng</a>
      <i class="fas fa-slash"></i>
      <a [class.active]="language === 'am'" (click)="onLangClick('am')">arm</a>
    </div>-->
  </div>

  <!-- Navigation mobile -->
  <div class="nav-mobile">
    <div class="logo-mobile">
      <a routerLink="/">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="52"
          height="52"
          viewBox="0 0 130.8 130.55"
        >
          <title>poligraf logo</title>
          <path
            d="M0,0V130.32H90.06V0ZM10,60.3H20V70.49H10Zm0,24.52H40.25v10.5H10ZM30,70.49V30H40.25V77.83Zm30.06,49.83H10v-10H60.06Zm0-19.56v2.06L50,95.32V30H60.06Zm20,19.56h-10V20H20V52.54L10.82,46,10,45.38V10H80.06Z"
            transform="translate(0 0.12)"
            fill="#dcd4c9"
          />
          <path
            d="M97.78,130.44v-7.15q0-.31,0-.68t.06-.75A3.37,3.37,0,0,1,99,120a5.43,5.43,0,0,1,3.54-.85h11.18q3.21,0,4,1.12a4.51,4.51,0,0,1,.91,2.48v3.13h11.6v4.6Zm17.9-5.14a1.91,1.91,0,0,0-.33-1.1,1.42,1.42,0,0,0-1.26-.48H102.42a1.92,1.92,0,0,0-1.18.31,1.53,1.53,0,0,0-.44,1.2l0,.62h14.93Z"
            transform="translate(0 0.12)"
            fill="#dcd4c9"
          />
          <path
            d="M102.49,112.69h-.08l-1,0a7.05,7.05,0,0,1-1.37-.23,4.68,4.68,0,0,1-1.91-1.14,3.58,3.58,0,0,1-.87-2.69v-3.13a3.65,3.65,0,0,1,.85-2.67,4.46,4.46,0,0,1,1.89-1.16,6.52,6.52,0,0,1,1.35-.23l1,0h24.36a4.57,4.57,0,0,1,.81.08,4.16,4.16,0,0,1,2.2.91q1,.79,1,3.11l0,3.13a.67.67,0,0,0,0,.21V109a4.76,4.76,0,0,1-.81,2.55q-.77,1.16-4.06,1.16Zm24.17-4.6h.12a1.22,1.22,0,0,0,.7-.31,1,1,0,0,0,.31-.77,1,1,0,0,0-.29-.75,1.12,1.12,0,0,0-.83-.29H101.33a1,1,0,0,0-.73.25,1.15,1.15,0,0,0-.35.56.13.13,0,0,0,0,.1V107a1,1,0,0,0,.31.73,1.12,1.12,0,0,0,.7.35h25.45Z"
            transform="translate(0 0.12)"
            fill="#dcd4c9"
          />
          <path
            d="M97.78,94.12v-4.6h29.47V84.38h3v9.74Z"
            transform="translate(0 0.12)"
            fill="#dcd4c9"
          />
          <path
            d="M97.78,78.23v-4.6h32.48v4.6Z"
            transform="translate(0 0.12)"
            fill="#dcd4c9"
          />
          <path
            d="M114.52,54.64h11.33q3.67,0,4.29,1.28a6.48,6.48,0,0,1,.62,2.86l0,3.21a4,4,0,0,1-.93,2.92A4.13,4.13,0,0,1,127.94,66a6.1,6.1,0,0,1-1,.14l-.93,0H102.42l-.95,0a10.1,10.1,0,0,1-1.06-.1,4.85,4.85,0,0,1-2.18-1,3.65,3.65,0,0,1-1-2.94V59.24q0-2.71,1-3.54a4,4,0,0,1,2.28-.91h7.5v4.6H101a.11.11,0,0,0-.08,0,.84.84,0,0,0-.54.31,1,1,0,0,0-.23.7,1.16,1.16,0,0,0,.27.75.83.83,0,0,0,.66.33h25.68a1,1,0,0,0,.73-.27,1.62,1.62,0,0,0,.39-.58.65.65,0,0,1,0-.15.6.6,0,0,0,0-.16,1.16,1.16,0,0,0-1.16-1.12h-9.13v1.08h-3.09Z"
            transform="translate(0 0.12)"
            fill="#dcd4c9"
          />
          <path
            d="M97.78,47.41V40.18q0-2.4,1-3.17a4.23,4.23,0,0,1,2.2-.89,5.23,5.23,0,0,1,.64,0h12.16a6.06,6.06,0,0,1,.66,0,5,5,0,0,1,1.8.5,3,3,0,0,1,1.37,1.58l12.65-3.05v4.91l-12,2.44v.31h12v4.6Zm17.56-5.26a1.27,1.27,0,0,0-.54-1.06,2.47,2.47,0,0,0-1.51-.41H102.42a1.73,1.73,0,0,0-1.28.37,1.35,1.35,0,0,0-.39.83v.93h14.58Z"
            transform="translate(0 0.12)"
            fill="#dcd4c9"
          />
          <path
            d="M97.78,26.14V19.22L130.26,16V20.5l-9,.77V24.4l9,.7v4.45Zm20.42-4.64-16.51,1.2v.23l16.51,1.28Z"
            transform="translate(0 0.12)"
            fill="#dcd4c9"
          />
          <path
            d="M97.78,9.28V-.12h3V4.68H112.2V.46h3V4.68h15.08v4.6Z"
            transform="translate(0 0.12)"
            fill="#dcd4c9"
          />
        </svg>
        <!--<img src="assets/images/logo/poligraf-mobile.png" alt="logo" />-->
      </a>
    </div>
    <div class="nav-btns">
      <div class="reg-log-btns">
        <a
          *ngFor="let navLink of navLinks$ | async"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: false }"
          [routerLink]="navLink.routerLinkCommands"
          [ngClass]="navLink.ngClass"
          (click)="navLink.action ? navLink.action() : $event.preventDefault()"
        >
          {{ navLink.text }}
        </a>

        <!-- Mobile about us menu opener-->
        <svg
          (click)="showMobileContacts()"
          style="cursor: pointer"
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#DCD4C9"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
          />
        </svg>
      </div>

      <!--<div class="lang-list" *ngrxLet="language$; let language">
        <a [class.active]="language === 'en'" (click)="onLangClick('en')">eng</a>
        <span>|</span>
        <a [class.active]="language === 'am'" (click)="onLangClick('am')">arm</a>
      </div>-->
    </div>
  </div>

  <div class="content-container">
    <!-- router-outlet -->
    <ng-content></ng-content>
  </div>
</section>

<ng-template #contacts>
  <div class="contacts">
    <div class="contact location">
      <h3>Location</h3>
      <p>{{ aboutUs.location.text }}</p>
      <a [href]="aboutUs.location.googleMapHref" target="_blank">
        Get Directions
      </a>
    </div>

    <!-- <div class="contact phone">
      <h3>Phone</h3>
      <a class="text-highlight" [href]="aboutUs.phone.href">
        {{ aboutUs.phone.text }}
      </a>
    </div> -->

    <div class="contact facebook">
      <h3>Facebook</h3>
      <a
        class="text-highlight"
        target="_blank"
        [href]="aboutUs.socialLinks.facebook.href"
      >
        {{ aboutUs.socialLinks.facebook.text }}
      </a>
    </div>

    <div class="contact instagram">
      <h3>Instagram</h3>
      <a
        class="text-highlight"
        target="_blank"
        [href]="aboutUs.socialLinks.instagram.href"
      >
        {{ aboutUs.socialLinks.instagram.text }}
      </a>
    </div>

    <div class="contact telegram">
      <h3>Telegram</h3>
      <a
        class="text-highlight"
        target="_blank"
        [href]="aboutUs.socialLinks.telegram.href"
      >
        {{ aboutUs.socialLinks.telegram.text }}
      </a>
    </div>

    <div class="contact soundcloud">
      <h3>Soundcloud</h3>
      <a
        class="text-highlight"
        target="_blank"
        [href]="aboutUs.socialLinks.soundcloud.href"
      >
        {{ aboutUs.socialLinks.soundcloud.text }}
      </a>
    </div>

    <div class="contact soundcloud">
      <h3>Tell us what you think</h3>
      <!-- if you want to open email links please read this one: https://www.timeatlas.com/email-links-chrome-gmail/ -->
      <a class="text-highlight" [href]="aboutUs.email.href" target="_blank">
        {{ aboutUs.email.text }}
      </a>
    </div>
  </div>
</ng-template>
