import { FactoryProvider, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { CookieModule, CookieService } from '@gorniv/ngx-universal';
import { JWT_OPTIONS, JwtModule, JwtConfig } from '@auth0/angular-jwt';
import { NgxMaskModule } from 'ngx-mask';
import {
  defaultErrorsMap,
  provideErrorMsgHashMap,
} from '@app/shared/form-error-message';

import { CoreModule, EnvironmentService } from './core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

function jwtOptionsFactory(
  cookieService: CookieService,
  envService: EnvironmentService
): JwtConfig {
  const { apiUrl, mediaUrl } = envService.getApiUrlsMap();

  return {
    tokenGetter: () => {
      try {
        return cookieService.get('usertoken');
      } catch (err) {
        return null;
      }
    },
    allowedDomains: [new URL(apiUrl).host, new URL(mediaUrl).host],
    disallowedRoutes: [`${apiUrl}/account/token`],
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'poligrafApp' }),
    // BrowserTransferStateModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TransferHttpCacheModule,
    CookieModule.forRoot(),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [CookieService, EnvironmentService],
      },
    }),
    NgxMaskModule.forRoot({ validation: true }),
    AppRoutingModule,
    CoreModule,
  ],
  providers: [provideErrorMsgHashMap(defaultErrorsMap)],
  bootstrap: [AppComponent],
})
export class AppBrowserModule {
  constructor() {}
}
