export type HashMap<T extends object> = { [key: string]: T };

export function createHashMap<T extends object, Key extends keyof T>(
  arr: T[],
  key: Key
) {
  const hashMap: HashMap<T> = {};

  for (let i = 0, len = arr.length; i < len; i++) {
    const entity = arr[i];
    const k = entity[key].toString();
    hashMap[k] = entity;
  }

  return hashMap;
}

export function stripTextFromHtml(htmlString: string) {
  return htmlString.replace(/(<([^>]+)>)/gi, '');
}
