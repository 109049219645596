import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ComponentStore, tapResponse } from '@ngrx/component-store';

import { AccountModel } from '@app/core/api/types';
import { AccountService, AuthService } from '@app/core/api';
import {
  GetCallState,
  stateLogger,
} from '@app/shared/utils/component-store-utils';
import { DOCUMENT } from '@angular/common';
import { IS_PLATFORM_BROWSER } from '@app/shared/tokens';

export interface State {
  account: AccountModel;
  callState: GetCallState;
  error: Error | null;
  configs: any;
  lang: 'en' | 'am';
}

@Injectable({ providedIn: 'root' })
export class RootStore extends ComponentStore<State> {
  constructor(
    private accountService: AccountService,
    private authService: AuthService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(IS_PLATFORM_BROWSER) private isPlatformBrowser: boolean
  ) {
    super({
      callState: GetCallState.Init,
      error: null,
      account: null,
      configs: null,
      lang: 'en',
    });

    if (isPlatformBrowser) {
      this.state$.subscribe(stateLogger('Root'));
    }

    this.loadAccountOnTokenChanges(this.authService.decodedToken$);
  }

  readonly account$ = this.select((state) => state.account);
  // .pipe(
  //   map((account) => ({
  //     ...account,
  //     // status: AccountStatus.Disabled,
  //     verification_status: AccountVerificationStatus.NotVerified,
  //   }))
  // );

  readonly loading$ = this.select(
    (state) => state.callState === GetCallState.Loading
  );
  readonly error$ = this.select((state) => state.error);
  readonly configs$ = this.select((state) => state.configs);
  readonly language$ = this.select((state) => state.lang);
  readonly isLoggedIn$ = this.select(this.account$, (account) => !!account);

  readonly ordersIsAvailable$ = this.select(
    this.authService.decodedToken$,
    (decodedToken) => !!decodedToken
  );

  readonly updateAccount = this.updater<Partial<AccountModel>>(
    (state, update) => ({
      ...state,
      account: {
        ...state.account,
        ...update,
      },
    })
  );

  private readonly loadAccountOnTokenChanges = this.effect(
    (origin$: Observable<Partial<AccountModel> | null>) =>
      origin$.pipe(
        tap(() => {
          this.patchState({ callState: GetCallState.Loading });
        }),
        switchMap((decodedToken) =>
          (decodedToken
            ? this.accountService.getAccount(decodedToken.id)
            : of<AccountModel>(null)
          ).pipe(
            tapResponse(
              (account) => {
                this.patchState({
                  account,
                  callState: GetCallState.Loaded,
                  error: null,
                });
              },
              (error: Error) => {
                this.patchState({
                  callState: GetCallState.Error,
                  error: new Error('something went wrong on Mars (server)'),
                });

                this.authService.logout();
              }
            )
          )
        )
      )
  );

  readonly changeLanguage = this.effect<{ lang: 'en' | 'am' }>((origin$) =>
    origin$.pipe(
      tap(({ lang }) => {
        this.patchState({ lang });
        this.document.documentElement.lang = lang;
      })
    )
  );
}
