import { Component, Inject } from '@angular/core';
import { IS_PLATFORM_BROWSER } from '@app/shared/tokens';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(@Inject(IS_PLATFORM_BROWSER) isPlatformBrowser: boolean) {
    if (isPlatformBrowser) {
      LOG_POWERED_BY_SCEON();
    }
  }
}

function LOG_POWERED_BY_SCEON() {
  const styles = [
    'color: white',
    'font-size: 20px',
    'font-weight: 600',
    'padding: 10px',
  ].join(';');

  console.log('%cpowered by "SCEON DEV"', styles);
}
