import { Configs } from './configs.data';

export { Configs };

export enum SocialLinkType {
  Facebook = 'facebook',
  Instagram = 'instagram',
  Twitter = 'twitter',
}

export enum AccountRole {
  Admin = 'admin',
  Customer = 'customer',
  Moderator = 'moderator',
}

export enum AccountStatus {
  NotVerified = 'not verified',
  Active = 'active',
  Disabled = 'disabled',
}

export enum AccountVerificationStatus {
  NotVerified = 'not verified',
  Pending = 'pending',
  Verified = 'verified',
  Rejected = 'rejected',
}

export interface AccountModel {
  id: number;
  role: AccountRole;
  full_name: string | null;
  email: string;
  status: AccountStatus;
  verification_status: AccountVerificationStatus;
  date_last_token_generated: string;
  date_created: string;
  date_modified: string | null;
  date_verified: string | null;
  date_of_birth: string | null;
  pending_email: string | null;
  city: string | null;
  phone: string | null;
  social_type: SocialLinkType | null;
  social_link: string | null;
}

export interface EventModel {
  id: number;
  type: EventType;
  title_en: string;
  title_am: string;
  description_en: string;
  description_am: string;
  image_url: string;
  on_door_price: number;
  current_price: number;
  status: EventStatus;
  date_start: Date;
  date_end: Date;
  publish_date: Date;
  created_by: number;
  last_modified_by: number;
  date_created: Date;
  date_last_modified: Date;
  disable_online_tickets: number;
  basket_type: BasketType;
  baskets?: EventBasket[];
}

export enum EventType {
  Private = 'private',
  Public = 'public',
}

export enum EventStatus {
  Enabled = 'enabled',
  Disabled = 'disabled',
}

export interface EventsRequestFilter {
  limit: number;
  offset: number;
  account_id: number;
  status: EventStatus;
  type: EventType;
  date_start: Date;
  date_end: Date;
}

export enum BasketType {
  Date = 'date',
  Count = 'count',
  DateOrCount = 'date_or_count',
}

export interface EventBasket {
  id: number;
  event_id: number;
  date_from: Date;
  date_to: Date;
  price: number;
  count: number | null;
  expired?: boolean;
  bought_tickets?: number;
}

export interface AccountReferToVerifyData {
  full_name: string;
  date_of_birth: string;
  city: string;
  phone: string;
  social_type: SocialLinkType;
  social_link: string;
}

export interface AccountUpdateData {
  city: string;
  phone: string;
}

export interface AccountResetPasswordData {
  account_id: number;
  hash: string;
  email: string;
  password: string;
}

export interface PhoneCountryCode {
  name: string;
  dial_code: string;
  code: string;
  flag: string;
}

export enum OrderStatus {
  Pending = 'pending',
  Paid = 'paid',
}

export enum OrderCurrency {
  AMD = 'AMD',
}

export interface OrderModel {
  id: number;
  account_id: number;
  event_id: number;
  payment_id: null;
  qr_code_url: string | null;
  used_tickets: number;
  currency: OrderCurrency;
  status: OrderStatus;
  ticket_count: number;
  ticket_price: number;
  total_price: number;
  date_created: Date;
  date_modified: Date | null;
}

export interface GetOrdersReqFilter {
  offset: number;
  limit: number;
  account_id?: number;
  event_id?: number;
  status?: OrderStatus;
}

export enum PageView {
  Mobile = 'MOBILE',
  Desktop = 'DESKTOP',
}

export interface CreateOrderReqData {
  event_id: number;
  currency: OrderCurrency;
  ticket_count: number;
  ticket_price: number;
  page_view: PageView;
}
