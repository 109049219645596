import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { createHashMap } from '@app/shared/utils/functions';

import {
  OrderModel,
  OrderCurrency,
  OrderStatus,
  GetOrdersReqFilter,
  CreateOrderReqData,
} from './types';

@Injectable({
  providedIn: 'root',
})
export class CommerceService {
  private readonly baseURL = `${environment.apiUrl}/commerce`;

  constructor(private http: HttpClient) {}

  getOrderById(orderId: number): Observable<OrderModel> {
    const url = `${this.baseURL}/orders/${orderId}`;

    return this.http.get<OrderModel>(url);
  }

  getOrders(
    filter: GetOrdersReqFilter
  ): Observable<{ totalCount: number; orders: OrderModel[] }> {
    const url = `${this.baseURL}/orders`;
    const params = this.createGetOrdersParams(filter);

    return this.http
      .get<{ count: number; data: OrderModel[] }>(url, { params })
      .pipe(
        map(({ count, data }) => ({ totalCount: count, orders: data })),
        catchError((err: HttpErrorResponse) => {
          if (err.status === 404) {
            return of({ totalCount: 0, orders: [] });
          }
          return throwError(err);
        })
      );
  }

  getOrderByEventId(eventId: number, accountId: number) {
    const url = `${this.baseURL}/orders`;
    const params = new HttpParams()
      .set('limit', '1')
      .set('offset', '0')
      .set('account_id', accountId.toString())
      .set('event_id', eventId.toString());
    return this.http
      .get<OrderModel[]>(url, { params })
      .pipe(map((res: any) => res.data));
  }

  createOrder(
    data: CreateOrderReqData
  ): Observable<{
    id: number;
    form_url: string | null;
  }> {
    const url = `${this.baseURL}/orders`;
    data = this.validateCreateOrderReqData(data);

    return this.http.post<{
      id: number;
      form_url: string | null;
    }>(url, data);
  }

  getBoughtTicketsByBasketIds(
    basketIds: number[]
  ): Observable<{
    [basketId: string]: { basket_id: number; bought_tickets: number };
  }> {
    const url = `${this.baseURL}/count_tickets_by_basket_ids`;

    return this.http
      .post<Array<{ basket_id: number; bought_tickets: number }>>(url, {
        basket_ids: basketIds,
      })
      .pipe(
        map((boughtTicketsByBaskets) =>
          createHashMap(boughtTicketsByBaskets, 'basket_id')
        )
      );
  }

  private createGetOrdersParams(filter: GetOrdersReqFilter): HttpParams {
    const { limit, offset, event_id, account_id, status } = filter;

    let params = new HttpParams()
      .set('limit', limit.toString())
      .set('offset', offset.toString());

    if (event_id) {
      params = params.set('event_id', event_id.toString());
    }

    if (account_id) {
      params = params.set('account_id', account_id.toString());
    }

    if (status) {
      params = params.set('status', status);
    }

    return params;
  }

  private validateCreateOrderReqData(
    data: CreateOrderReqData
  ): CreateOrderReqData | null {
    return data;
  }
}
