<div class="header">
  <svg
    (click)="overlayRef.detach()"
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    fill="#FFFFFF"
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path
      d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
    />
  </svg>
</div>

<div class="content">
  <div class="philosophy">
    <p [innerHTML]="aboutUs.philosophy"></p>
  </div>

  <div class="contact location">
    <h3>Location</h3>
    <p>{{ aboutUs.location.text }}</p>
    <a [href]="aboutUs.location.googleMapHref" target="_blank">
      Get Directions
    </a>
  </div>

  <!-- <div class="contact phone">
    <h3>Phone</h3>
    <a class="text-highlight" [href]="aboutUs.phone.href">
      {{ aboutUs.phone.text }}
    </a>
  </div> -->

  <div class="contact facebook">
    <h3>Facebook</h3>
    <a
      class="text-highlight"
      [href]="aboutUs.socialLinks.facebook.href"
      target="_blank"
    >
      {{ aboutUs.socialLinks.facebook.text }}
    </a>
  </div>

  <div class="contact instagram">
    <h3>Instagram</h3>
    <a
      class="text-highlight"
      [href]="aboutUs.socialLinks.instagram.href"
      target="_blank"
    >
      {{ aboutUs.socialLinks.instagram.text }}
    </a>
  </div>

  <div class="contact telegram">
    <h3>Telegram</h3>
    <a
      class="text-highlight"
      [href]="aboutUs.socialLinks.telegram.href"
      target="_blank"
    >
      {{ aboutUs.socialLinks.telegram.text }}
    </a>
  </div>

  <div class="contact soundcloud">
    <h3>Soundcloud</h3>
    <a
      class="text-highlight"
      [href]="aboutUs.socialLinks.soundcloud.href"
      target="_blank"
    >
      {{ aboutUs.socialLinks.soundcloud.text }}
    </a>
  </div>

  <div class="contact soundcloud">
    <h3>Tell us what you think</h3>
    <a class="text-highlight" [href]="aboutUs.email.href">
      {{ aboutUs.email.text }}
    </a>
  </div>
</div>
