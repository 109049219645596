import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AboutUsService {
  readonly aboutUs = {
    philosophy:
      '"Poligraf" is a multi-functional space containing multiple moods and vibes, where each can find their reflection. We host various types of events, such as clubnights, concerts, workshops, art exhibitions and more.',
    location: {
      text: '19a Koryun st. Yerevan, Armenia',
      googleMapHref:
        'https://www.google.com/maps/dir//Poligraf,+19a+Koryun+St,+Yerevan+0009,+Armenia/@40.1894415,44.5190589,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x406abdaedee662e3:0x1e8c5f9dca877252!2m2!1d44.5212476!2d40.1894415',
    },
    phone: {
      text: '+374 (44) 191-419',
      href: 'tel:+37444191419',
    },
    socialLinks: {
      facebook: {
        text: 'facebook.com/poligrafevn',
        href: 'https://www.facebook.com/poligrafevn',
      },
      instagram: {
        text: 'instagram.com/poligrafevn',
        href: 'https://www.instagram.com/poligrafevn',
      },
      telegram: {
        text: 't.me/poligrafevn',
        href: 'https://t.me/poligrafevn',
      },
      soundcloud: {
        text: 'soundcloud.com/poligrafevn',
        href: 'https://www.soundcloud.com/poligrafevn',
      },
    },
    email: {
      text: 'info@poligraf.am',
      href: 'mailto:info@poligraf.am?subject=Poligraf Feedback',
    },
  } as const;

  constructor() {}
}
